import React from 'react';
import { DescriptionList, CalloutCard, ResourceList, Card, Avatar, TextStyle } from '@shopify/polaris';
import { dashboardStats } from './../components/api/functions';
import DashboardLoading from './../components/misc/dashboardloading';

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            loading: true
        }
        this.shop = this.props.shop;
        this.id = this.props.id;
    }

    handleTabChange = (selectedTabIndex) => {
        this.setState({selected: selectedTabIndex});
    };

    componentDidMount = () => {
        dashboardStats(this.shop, this.id)
        .then((data) => {

            this.setState({
                loading: false
            });
        })
        .catch((err) => {
            console.log(err);
        });
    }

    render() {

        return (
            <div>
                {this.state.loading && 
                    <DashboardLoading/>
                }
                {!this.state.loading && 
                    <DescriptionList
                        items={[
                            {
                                term: <div>Recent Activity <p>Some of the recent things that have happened</p></div>,
                                description:
                                    <Card>
                                        <ResourceList
                                        resourceName={{singular: 'customer', plural: 'customers'}}
                                        items={[
                                            {
                                            id: 341,
                                            url: 'customers/341',
                                            name: 'Mae Jemison',
                                            location: 'Decatur, USA',
                                            },
                                            {
                                            id: 256,
                                            url: 'customers/256',
                                            name: 'Ellen Ochoa',
                                            location: 'Los Angeles, USA',
                                            },
                                        ]}
                                        renderItem={(item) => {
                                            const {id, url, name, location} = item;
                                            const media = <Avatar customer size="medium" name={name} />;
                                    
                                            return (
                                            <ResourceList.Item
                                                id={id}
                                                url={url}
                                                media={media}
                                                accessibilityLabel={`View details for ${name}`}
                                            >
                                                <h3>
                                                <TextStyle variation="strong">{name}</TextStyle>
                                                </h3>
                                                <div>{location}</div>
                                            </ResourceList.Item>
                                            );
                                        }}
                                        />
                                    </Card>
                            },
                            {
                                term: <div>Search Products <p>Take a look at what you can sell</p></div>,
                                description: 
                                    <CalloutCard
                                        title="Search other stores for products"
                                        illustration="/images/products.png"
                                        primaryAction={{
                                            content: 'Search Products',
                                            url: "/search"
                                        }}
                                        secondaryAction={{
                                            content: 'Learn how it works.',
                                            onAction: () => alert("Support")
                                        }}
                                    >
                                        <p>Sell other people's products on your store and make a profit.</p>
                                    </CalloutCard>
                            },
                            {
                                term: <div>Add Your Products <p>Some or all of your products.</p></div>,
                                description:
                                    <CalloutCard
                                        title="Let other people sell your products"
                                        illustration="/images/add.png"
                                        primaryAction={{
                                            content: 'Add Products',
                                            url: "/add"
                                        }}
                                        secondaryAction={{
                                            content: 'Learn how it works.',
                                            onAction: () => alert("Support")
                                        }}
                                    >
                                        <p>Use the Connex app as a way to market to customers you may not reach otherwise.</p>
                                    </CalloutCard>
                            }
                        ]}
                    />
                }
            </div>
        )
    }

}

export default Dashboard;