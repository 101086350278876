import React from 'react';
import ReactQueryParams from 'react-query-params';
import { AppProvider, Page, Banner } from '@shopify/polaris';
import Loading from './../loading';

class Install extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            message: "Authorizing app..."
        }
    }

    componentDidMount = () => {
        const api_url = "https://us-central1-connex-shopify-app.cloudfunctions.net";
        const p = this.queryParams;

        var endpoint = "/install?shop=" + p.shop + "&hmac=" + p.hmac + "&timestamp=" + p.timestamp + "&code=" + p.code + "&state=" + p.state;
        fetch(api_url + endpoint)
        .then((data) => data.json())
        .then((res) => {
            if (res.success) {
                this.setState({
                    message: "Excellent! Connex is installed, you're being redirected to the app.",
                    error: false,
                    success: true
                });
                window.location.href = res.url;
                
            } else {
                this.setState({
                    message: res.error,
                    error: true
                });
            }
        })
        .catch((err) => {
            this.setState({
                message: err,
                error: true
            });
        })
    };
    
    action = () => {
        window.history.back();
    }

    render() {
        return (
            <AppProvider>
                <Page>
                    {this.state.error &&
                        <Banner
                            title="App installation failed"
                            action={{content: 'Go Back', onAction: this.action}}
                            status="critical"
                        >
                            <p>It looks like we couldn't install the app on your store. Please go back and try again.</p>
                        </Banner>
                    }
                    {!this.state.success && !this.state.error &&
                        <Loading/>
                    }
                    {this.state.success && 
                        <Banner
                            title={this.state.message}
                            status="success"
                        />
                    }
                </Page>
            </AppProvider>
        )
    }
}

export default Install;