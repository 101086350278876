import React from 'react';
import {
  AppProvider, Page, Layout, Card, OptionList, 
  SkeletonBodyText, ResourceList, Thumbnail, TextStyle,
  CustomerListFooter, Pagination
} from '@shopify/polaris';
import { Modal } from '@shopify/polaris/embedded';
import { getCategories, searchProducts } from './api/functions';

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      empty: false,
      categoryId: '',
      categories: [],
      searching: true,
      query: '',
      items: [],
      selectedItems: [],
      showSource: false,
      productSource: '',
      searchProducts: true,
      page: 1,
      previous: false,
      next: false
    }
  }

  componentDidMount() {
    getCategories()
    .then((cats) => {
        this.setState({
            categories: cats
        });
        this.getProducts();
    })
  }

  changeCategory = (catId) => {
    this.setState({
      categoryId: catId
    });
    this.getProducts();
  }

  paginateProducts = (p) => {
    this.setState({
      page: this.state.page + p
    });
    var { query, categoryId, page } = this.state;
    this.setState({
      searchProducts: true,
      previous: (page === 1) ? false : true
    });
    searchProducts(query, categoryId, page)
    .then((results) => {
      console.log(results)
      this.setState({
        searching: false,
        items: results.hits,
        searchProducts: false,
        next: (results > 19) ? true : false
      });
    })
    .catch((err) => console.log(err))
  }

  getProducts = () => {
    var { query, categoryId } = this.state;
    this.setState({
      searchProducts: true
    });
    searchProducts(query, categoryId, 1)
    .then((results) => {
      console.log(results)
      this.setState({
        searching: false,
        items: results.hits,
        searchProducts: false,
        next: (results > 19) ? true : false
      });
    })
    .catch((err) => console.log(err))
  } 

  renderItem = (item) => {
    const {objectID, url, image, title, discount} = item;
    const media = <Thumbnail source={image} alt={title}/>;
    const shortcutActions = [{content: 'Preview', onAction: () => this.loadModal(url)}];

    return (
      <ResourceList.Item id={objectID} shortcutActions={shortcutActions} persistActions media={media}>
        <h3>
          <TextStyle variation="strong">{title}</TextStyle>
        </h3>
        <div>Discount: From {discount} off</div>
      </ResourceList.Item>
    );
  };

  selectChange = (items) => {
    this.setState({
      selectedItems: items
    });
  }

  loadModal = (url) => {
    console.log(url);
    this.setState({
      productSource: url,
      showSource: true
    });
  }

  render() {

    const shop = sessionStorage.getItem('shop');
    let categories = this.state.categories;

    const items = this.state.items;

    const paginationMarkup = items.length > 0
      ? (
          <Pagination
            hasPrevious={this.state.previous}
            hasNext={this.state.next}
            onPrevious={() => this.paginateProducts(-1)}
            onNext={() => this.paginateProducts(1)}
          />
      )
      : null;

    const resourceName = {
      singular: 'product',
      plural: 'products',
    };

    const filterControl = (
      <ResourceList.FilterControl
        filters={[]}
        searchValue={this.state.query}
        onSearchChange={(query) => this.setState({query})}
        additionalAction={{
          content: 'Search',
          onAction: () => this.getProducts(),
        }}
      />
    );

    const bulkActions = [
      {
        content: 'Add Products',
        onAction: () => console.log('Todo: add all products'),
      }
    ];

    if (shop !== "" && shop !== undefined) {
      return (
        <AppProvider
          apiKey="b16f2cf863476e8208dfe48a61e1bc7b" 
          shopOrigin={encodeURI(`https://${shop}`)}
          forceRedirect={false}
        >
            <Page 
                title="Connex Dashboard" 
                separator
                fullWidth={false}
                title="Search Products"
                primaryAction={{
                    content: 'Add My Products',
                    onAction: () => window.location.href = "/add"
                }}
            >
                <Layout>
                    <Layout.Section secondary>
                        <Card>
                            <OptionList
                            title="Product Category"
                            onChange={(updated) => {
                                this.changeCategory(updated);
                            }}
                            options={categories}
                            selected={this.state.categoryId}
                            />
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                      <Card>
                        {this.state.searching &&
                          <div>
                            <Card sectioned>
                              <SkeletonBodyText />
                            </Card>
                            <Card sectioned>
                              <SkeletonBodyText />
                            </Card>
                            <Card sectioned>
                              <SkeletonBodyText />
                            </Card>
                            <Card sectioned>
                              <SkeletonBodyText />
                            </Card>
                            <Card sectioned>
                              <SkeletonBodyText />
                            </Card>
                          </div>
                        }  
                        {!this.state.searching &&
                          <Card>
                            <ResourceList
                              bulkActions={bulkActions}
                              resourceName={resourceName}
                              selectedItems={this.state.selectedItems}
                              items={items}
                              renderItem={this.renderItem}
                              filterControl={filterControl}
                              loading={this.state.searchProducts}
                              onSelectionChange={(items) => this.selectChange(items)}
                            />
                            {paginationMarkup}
                          </Card>
                        }
                      </Card>
                    </Layout.Section>
                </Layout>
                <Modal
                  open={this.state.showSource}
                  onClose={() => this.setState({showSource: false})}
                  title="Product Preview"
                  large={true}
                  primaryAction={{
                    content: 'Close',
                    onAction: () => this.setState({showSource: false}),
                  }}
                  src={this.state.productSource}
                />
            </Page>
        </AppProvider>
      );
    } else {
      return (
        <AppProvider>
          <Page title="Connex Dashboard" separator>No shop found, please load the app from your Shopify admin dashboard.</Page>
        </AppProvider>
      );
    }
  }
}

export default Search;