import React from 'react';
import ReactQueryParams from 'react-query-params';
import Dashboard from './dashboard';
import {
  AppProvider, Page
} from '@shopify/polaris';

class Home extends ReactQueryParams {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      empty: false,
    }
  }

  addAccount = () => {
    window.location.href = "/add";
  }

  componentDidMount() {

  }

  render() {

    const shop = this.queryParams.shop;
    const id = this.queryParams.i;
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('shop', shop);

    if (shop !== "" && shop !== undefined) {
      return (
        <AppProvider
          apiKey="b16f2cf863476e8208dfe48a61e1bc7b" 
          shopOrigin={encodeURI(`https://${shop}`)}
          forceRedirect={false}
        >
          <Page 
            title="Connex Dashboard" 
            fullWidth={false}
            separator
            title="Home"
            secondaryActions={[
              {
                content: 'Search Products',
                onAction: () => window.location.href = "/search"
              }, 
              {content: 'View on your store'}
            ]}
            primaryAction={{
              content: 'Add My Products',
              onAction: () => window.location.href = "/add"
            }}
          >
            <Dashboard shop={shop} id={id} params={this.queryParams} parentstate={this}/>
          </Page>
        </AppProvider>
      );
    } else {
      return (
        <AppProvider>
          <Page title="Connex Dashboard" separator>No shop found, please load the app from your Shopify admin dashboard.</Page>
        </AppProvider>
      );
    }
  }
}

export default Home;