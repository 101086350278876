import React from 'react';
import {  AppProvider, Page, FooterHelp, Link
} from '@shopify/polaris';
import { productListings, saveListings, getCategories } from './api/functions';
import Loading from './loading';
import MainContent from './maincontent';
import toastr from "reactjs-toastr";
import 'reactjs-toastr/lib/toast.css';
//YOU WERE ADDING THE SLIDE DOWN FOR BULK PRICE CHANGES

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            loading: true,
            pageLoading: false,
            products: [],
            page: 0,
            previous: false,
            next: false,
            startAfter: '',
            categories: [],
            save_text: 'Save & Next Page'
        };
    }

    componentDidMount() {
        getCategories()
        .then((cats) => {
            this.setState({
                categories: cats
            });
            this.searchProducts(1);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    searchProducts = (nav, prod_state) => {
        const id = sessionStorage.getItem('id');
        const shop = sessionStorage.getItem('shop');
        var chunks = [], save_text = '', stop = false;
        if (prod_state) {
            for (var key in prod_state) {
                if (key.indexOf('category_') > -1) {
                    if (prod_state[key] === "") {
                        stop = true;
                    }
                }
            }
        }
        if (!stop) {
            this.setState({
                products: []
            });
            productListings(shop, id, this.state.startAfter, nav, prod_state)
            .then((res) => {
                if (!res.finished) {
                    var products = res.data;
                    var page = (products.length > 0) ? this.state.page + nav : this.state.page;
                    var next = false, previous = false;
                    if (products.length > 24) {
                        next = true;
                        save_text = 'Save & Next Page';
                    } else {
                        save_text = 'Save & Finish';
                    }

                    previous = (page > 1) ? true : false;
                    
                    for (var x = 0; x < products.length; x += 5) {
                        chunks.push(products.slice(x,x+5));
                    }

                    this.setState({
                        products: chunks,
                        page: page,
                        next: next,
                        previous: previous,
                        startAfter: res.start_after,
                        save_text: save_text
                    });
                } else {
                    window.location.href = "/?shop=" + shop + "&id=" + id;
                }
            })
            .catch((err) => console.log(err));
        } else {
            toastr.error(
                'You must select a category for all products and a price for all variants.', 
                'Error Saving', 
                { width:'auto', displayDuration: 3000 }
            );
        }
    }

    saveSelection = (shop, id) => {
        this.setState({
            loading: true
        });
        let data = this.state.selectedItems;
        saveListings(shop, id, data)
        .then((rs) =>  {
            window.location.href = "/configure"
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                loading: false
            });
        })
    }

    render() {

        const id = sessionStorage.getItem('id');
        const shop = sessionStorage.getItem('shop');

        return (
            <AppProvider
                apiKey="b16f2cf863476e8208dfe48a61e1bc7b" 
                shopOrigin={encodeURI(`https://${shop}`)}
                forceRedirect={false}
            >
                <Page 
                    title="Add Products to Connex"
                    secondaryActions={[{
                        content: 'Dashboard',
                        url: "/?shop=" + shop + "&i=" + id,
                        target: "app"
                    }]}
                    fullWidth={true}
                >
                    {this.state.products.length > 0 &&
                        <MainContent page={this.state.page} parentState={this} products={this.state.products} categories={this.state.categories}/>
                    }
                    {!this.state.products.length > 0 &&
                        <Loading/>
                    }
                    <FooterHelp>
                        Get help with setting up{' '}
                        <Link url="https://applow.com/connex">
                            here
                        </Link>
                        .
                    </FooterHelp>
                </Page>
            </AppProvider>
        );
    }
}