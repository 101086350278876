import React from 'react';
import { Thumbnail, Card, Banner,
    TextField, Layout, Select, Collapsible, 
    Button, FormLayout, ButtonGroup
} from '@shopify/polaris';
import Loading from './loading';

export default class MainContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            categories: this.props.categories,
            pageLoading: false,
            bulk: false,
            modal: false,
            bulk_perc: false,
            bulk_cat: false,
            discount: '',
            category: '',
            bulk_actions: false,
            discount_error: '',
            output: [],
            mounted: false,
            page: 1,
            save_text: "Save & Next Page"
        };
        this.tmpState = [];
    }

    componentDidMount() {
        this.tmpState.map((item) => {
            this.setState({
                [item.name]: item.value
            });
        });
        this.setState({
            pageLoading: false,
            products: this.props.products
        });
    }

    loadScreen = () => {
        var output = [];
        if (this.props.products.length > 4) {
            this.addToTmp("save_text", "Save & Next Page");
        } else {
            this.addToTmp("save_text", "Save & Finish");
        }
        this.props.products.map((chunk, y) => {
            var categories = this.state.categories;
            var row = chunk.map((item, x) => {
                var title = item.title;
                var catKey = `category_${item.product_id}`;
                var detailKey = `details_${item.product_id}`;
                var labelKey = `label_${item.product_id}`;
                var media = (item.images.length > 0) ? <Thumbnail source={item.images[0].src} alt={item.title}/> : '';

                this.addToTmp(detailKey, false);
                this.addToTmp(labelKey, 'Set Prices');
                this.addToTmp(catKey, (item.category) ? item.category : '');

                var price = [];
                item.variants.map((variant, i) => {
                    var priceKey = `price_${item.product_id}_${variant.id}`;
                    this.addToTmp(priceKey, (this.state[priceKey]) ? this.state[priceKey] : variant.price);
                    price.push(
                        <TextField 
                            key={i}
                            label={variant.title} 
                            type="number" 
                            value={(!this.state[priceKey]) ? variant.price : this.state[priceKey]} 
                            onChange={(val) => this.setState({ [priceKey]: val })}
                        />
                    )
                });

                return (
                    <Layout.Section key={x} secondary>
                        <Card title={media} 
                            actions={[{
                                content: (!this.state[labelKey]) ? 'Set Prices' : this.state[labelKey],
                                onAction: () => {
                                    this.setState({
                                        [detailKey]: !this.state[detailKey],
                                        [labelKey]: (this.state[detailKey]) ? 'Set Prices' : 'Hide Prices'
                                    })
                                }
                            }]}
                        >
                            <Card.Section title={<div style={{minHeight: 48 + "px"}}>{title}</div>}>
                                <Select
                                    label="Product Category"
                                    options={categories}
                                    onChange={(c) => this.setState({catKey: c})} 
                                    value={this.state[catKey]}
                                />
                            </Card.Section>
                            <Collapsible 
                                open={this.state[detailKey]} 
                                id={detailKey}
                            >
                                <Card.Section title="Set variant prices">
                                    {price}
                                </Card.Section>
                            </Collapsible>
                        </Card>
                    </Layout.Section>
                )
            });
            output.push(<div key={y} style={{marginTop: 18 + "px", marginBottom: 18 + "px"}}><Layout>{row}</Layout></div>);
        });
        return output;
    }

    handleChange(field, value) {
        this.setState({ [field]: value });
    }

    getValue = (key) => {
        return this.state[key];
    }
    
    addToTmp = (name, val) => {
        this.tmpState.push({"name": name, "value": val})
    }

    applyBulkAction = () => {
        var disc = this.state.discount;
        var cat = this.state.category;
        if (cat != "") {
            var update = {};
            Object.keys(this.state).forEach((item) => {
                if (item.substring(0, 9) == "category_") {
                    update[item] = cat
                }
            });
            this.setState(update);
        } 
        if (disc != "") {
            if (isNaN(disc) || disc < 1 || disc > 99) {
                this.setState({
                    discount_error: "A number between 1 and 99 (%)."
                });
            } else {
                var update = {"discount_error": ""};
                Object.keys(this.state).forEach((item) => {
                    if (item.substring(0, 6) == "price_") {
                        var current = this.state[item];
                        var new_price = (current/100) * disc;
                        update[item] = (current - new_price).toFixed(2);
                    }
                });
                this.setState(update);
            }
        } else {
            this.setState({
                discount_error: ""
            });
        }
    }

    render() {
        var output = this.loadScreen();
        if (!this.state.pageLoading) { 
            if (this.props.products.length > 0) {
                return (
                    <div>
                        <Banner
                            title="Setting prices"
                            status="info"
                            >
                            <p>
                                We use your prices by default, so only change them if you want to 
                                offer them at a different price to those that dropship them.
                            </p>
                        </Banner>
                        <div style={{marginTop: 20 + "px"}}>
                            <Card>
                                <Card.Header
                                    title="You'll now need to set the category for each product."
                                >
                                    <Button primary onClick={() => this.setState({bulk_actions: !this.state.bulk_actions})}>
                                        Bulk Actions
                                    </Button>
                                </Card.Header>
                                <Card.Section>
                                    <p>This will make it easier for your products to be found by others that look to sell it on your behalf.</p> 
                                    <p>To allow a discount for each variant, you can adjust the price here too.</p>
                                </Card.Section> 
                                <Collapsible 
                                    open={this.state.bulk_actions} 
                                    id="bulk_actions"
                                >
                                    <Card.Section>
                                        <FormLayout>
                                            <FormLayout.Group>
                                                <Select
                                                    label="Product Category"
                                                    options={this.state.categories}
                                                    onChange={(c) => this.setState({category: c})} 
                                                    value={this.state.category}
                                                />
                                                <TextField
                                                    label="Discount"
                                                    type="number"
                                                    min="1"
                                                    max="99"
                                                    suffix="%"
                                                    value={this.state.discount}
                                                    onChange={(d) => this.setState({discount: d})}
                                                    error={this.state.discount_error}
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <Button primary onClick={() => this.applyBulkAction()}>
                                                    Apply
                                                </Button>   
                                            </FormLayout.Group>
                                        </FormLayout>
                                    </Card.Section>
                                </Collapsible>
                            </Card>
                        </div>
                        <div style={{textAlign: 'center', marginTop: 20 + "px"}}>
                            <ButtonGroup style={{alignSelf: 'center'}}>
                                <Button 
                                    disabled={!this.props.parentState.state.previous}
                                    onClick={() => this.props.parentState.searchProducts(-1, this.state)}
                                >Prev. Page</Button>
                                <Button 
                                    primary 
                                    onClick={() => this.props.parentState.searchProducts(1, this.state)}
                                >{this.state.save_text}</Button>
                            </ButtonGroup>
                        </div>
                        {output}
                        <div style={{textAlign: 'right', marginTop: 20 + "px"}}>
                            <ButtonGroup style={{alignSelf: 'right'}}>
                                <Button 
                                    disabled={!this.props.parentState.state.previous}
                                    onClick={() => this.props.parentState.searchProducts(-1, this.state)}
                                >Prev. Page</Button>
                                <Button 
                                    primary 
                                    onClick={() => this.props.parentState.searchProducts(1, this.state)}
                                >{this.state.save_text}</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                );
            } else {
                return (
                    <Banner
                        title="No products available."
                        status="info"
                        >
                        <p>To get started, you need to make the products available to Connex from the Shopify Products tab.</p>
                    </Banner>
                );
            }
        } else {
            return (
                <Loading/>
            )
        }
    }
}