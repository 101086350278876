import React from 'react';
import ReactQueryParams from 'react-query-params';
import { AppProvider, Page, Banner } from '@shopify/polaris';
import Loading from './../loading';

class Auth extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            message: "Authorizing app...",
            success: false,
            error: false,
            current_user: false
        }
    }

    action = () => {
        window.history.back();
    }

    componentDidMount = () => {
        const p = this.queryParams;
        const api_url = "https://us-central1-connex-shopify-app.cloudfunctions.net";

        var endpoint = "/permission?shop=" + p.shop + "&hmac=" + p.hmac + "&timestamp=" + p.timestamp;
        fetch(api_url + endpoint)
        .then((data) => data.json())
        .then((res) => {
            console.log(JSON.stringify(res));
            if (res.success) {
                this.setState({
                    message: "We've received your installation request. You're now being redirected...",
                    error: false,
                    success: true,
                    current_user: res.current_user
                });
                window.location.href = res.url;
                
            } else {
                this.setState({
                    message: res.error,
                    error: true
                });
            }
        })
        .catch((err) => {
            this.setState({
                message: err,
                error: true
            });
        })
    };
    

    render() {
        return (
            <AppProvider>
                <Page>
                    {this.state.error &&
                        <Banner
                            title="App installation failed"
                            action={{content: 'Go Back', onAction: this.action}}
                            status="critical"
                        >
                            <p>It looks like we couldn't install the app on your store. Please go back and try again.</p>
                        </Banner>
                    }
                    {!this.state.success && !this.state.error &&
                        <Loading/>
                    }
                    {this.state.success && !this.state.current_user &&
                        <Banner
                            title={this.state.message}
                            status="success"
                        />
                    }
                </Page>
            </AppProvider>
        )
    }
}

export default Auth;