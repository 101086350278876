import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';
import Home from './components/home';
import Auth from './components/api/auth';
import Install from './components/api/install';
import Add from './components/add';
import Search from './components/search';

const App = () => (
    <BrowserRouter>
        <Switch>   
            <Route exact path="/" component={Home}/>
            <Route path="/dashboard" component={Home}/>
            <Route path="/auth" component={Auth}/>
            <Route path="/install" component={Install}/>
            <Route path="/add" component={Add}/>
            <Route path="/search" component={Search}/>
        </Switch>
    </BrowserRouter>
)

export default App;