const api_url = "https://us-central1-connex-shopify-app.cloudfunctions.net";

export function dashboardStats(shop, id) {
    return new Promise((resolve, reject) => {
        var endpoint = "/dashboard?shop=" + shop + "&id=" + id; 
        fetch(api_url + endpoint)
        .then((res) => res.json())
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export function productListings(shop, id, startAfter, direction, prod_state) {
    return new Promise((resolve, reject) => {
        var endpoint = "/listings"; 
        fetch(api_url + endpoint, {
            method: "POST",
            body: JSON.stringify({
                "start": startAfter,
                "shop": shop,
                "id": id,
                "direction": direction,
                "state": prod_state
            })
        })
        .then((res) => res.json())
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export function saveListings(shop, id, data) {
    return new Promise((resolve, reject) => {
        var endpoint = "/save_listings?shop=" + shop + "&id=" + id; 
        fetch(api_url + endpoint, {
            method: "POST",
            body: JSON.stringify({"listings": data})
        })
        .then(function(response) {
            resolve(response);
        }).catch((err) => {
            reject(err);
        })
    });
}

export function getCategories() {
    return new Promise((resolve, reject) => {
        var endpoint = "/categories"; 
        fetch(api_url + endpoint)
        .then((res) => res.json())
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export function searchProducts(q, categoryid, page) {
    return new Promise((resolve, reject) => {
        var endpoint = "/search?search=" + encodeURI(q) + "&cat=" + encodeURI(categoryid) + "&page=" + page; 
        fetch(api_url + endpoint)
        .then((res) => res.json())
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        })
    })
}